import Vue from 'vue'
import App from './App.vue'
import { router } from '@/router'
import store from './store'

import apolloProvider from './apollo'

import BootstrapVue from 'bootstrap-vue'
import Vue2Filters from 'vue2-filters'
import VueAgile from 'vue-agile'
import BackToTop from 'vue-backtotop'

Vue.use(BootstrapVue)
Vue.use(Vue2Filters)
Vue.use(VueAgile)
Vue.use(BackToTop)

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})
Vue.filter('two_digits', (value) => {
  if (value < 0) {
    return '00';
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
});

Vue.component('Navbar', () => import('@/public/components/Navbar.vue'));
Vue.component('Foot', () => import('@/public/components/Foot.vue'));

Vue.component('Appbar', () => import('@/private/components/Appbar.vue'));

Vue.component('Business', () => import('@/public/components/Business.vue'));
Vue.component('Services', () => import('@/public/components/Services.vue'));
Vue.component('Products', () => import('@/public/components/Products.vue'));
/* Vue.component('Clients', () => import('@/public/components/Clients.vue')); */
Vue.component('Projects', () => import('@/public/components/Projects.vue'));
Vue.component('Development', () => import('@/public/components/Development.vue'));

Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  store,
  apolloProvider,
  template: '<App/>',
  components: {
    App
  },
  // created() {
  //   this.$store.dispatch('currentUser')
  // },
})
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// Containers
const PublicContainer = () => import('@/public/PublicContainer')
const PrivateContainer = () => import('@/private/PrivateContainer')

// Pages
const Signin = () => import('@/public/pages/Signin')
const Cloud = () => import('@/public/pages/Cloud')
const Develop = () => import('@/public/pages/Develop')
const Umbraco = () => import('@/public/pages/develop/Umbraco')
const Vuejs = () => import('@/public/pages/develop/Vuejs')
const Launch = () => import('@/public/pages/Launch')

const WhatWeDo = () => import('@/public/pages/WhatWeDo')
const Contact = () => import('@/public/pages/Contact')
const PrivacyPolicy = () => import('@/public/pages/PrivacyPolicy')

const Work = () => import('@/public/pages/Work')
const Goldentickettravel = () => import('@/public/pages/projects/Goldentickettravel')
const Havenstar = () => import('@/public/pages/projects/Havenstar')
const Holidaygems = () => import('@/public/pages/projects/Holidaygems')
const Irispayroll = () => import('@/public/pages/projects/Irispayroll')
const Sunmaster = () => import('@/public/pages/projects/Sunmaster')
const Travelplaces = () => import('@/public/pages/projects/Travelplaces')

const Notifications = () => import('@/private/pages/Notifications')

export const router = new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: PublicContainer,
      children: [
        {
          path: '',
          name: '',
          component: Launch
        },
        {
          path: '/cloud',
          name: 'cloud',
          component: Cloud
        },
        {
          path: '/develop',
          name: 'develop',
          component: Develop
        },
        {
          path: '/develop/umbraco',
          name: 'umbraco',
          component: Umbraco
        },
        {
          path: '/develop/vuejs',
          name: 'vuejs',
          component: Vuejs
        },
        {
          path: '/signin',
          name: 'signin',
          component: Signin
        },
        {
          path: '/work',
          name: 'work',
          component: Work
        },
        {
          path: '/work/goldentickettravel',
          name: 'goldentickettravel',
          component: Goldentickettravel
        },
        {
          path: '/work/havenstar',
          name: 'havenstar',
          component: Havenstar
        },
        {
          path: '/work/holidaygems',
          name: 'holidaygems',
          component: Holidaygems
        },
        {
          path: '/work/irispayroll',
          name: 'irispayroll',
          component: Irispayroll
        },
        {
          path: '/work/sunmaster',
          name: 'sunmaster',
          component: Sunmaster
        },
        {
          path: '/work/travelplaces',
          name: 'travelplaces',
          component: Travelplaces
        },

        {
          path: '/contact',
          name: 'contact',
          component: Contact
        },
        {
          path: '/privacy-policy',
          name: 'privacypolicy',
          component: PrivacyPolicy
        },
        {
          path: '/what-we-do',
          name: 'whatwedo',
          component: WhatWeDo
        }

      ]
    },
    {
      path: "/",
      component: PrivateContainer,
      children: [
        {
          path: '',
          name: '',
          component: Launch
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: Notifications
        },
      ]
    },
  ]
});
